import { initializeApp } from "firebase/app";
import {
  getFirestore,
  addDoc,
  serverTimestamp,
  collection,
} from "firebase/firestore";

const firebaseConfig = {
  apiKey: "AIzaSyC2bx2UqA0M28CTv9v6ETlhUuniH1pkgfo",
  authDomain: "bday-9f90c.firebaseapp.com",
  projectId: "bday-9f90c",
  storageBucket: "bday-9f90c.appspot.com",
  messagingSenderId: "584829924099",
  appId: "1:584829924099:web:287b65344f2354c0e66f36",
  measurementId: "G-EBPLJHBMR6",
};

const app = initializeApp(firebaseConfig);
const db = getFirestore(app);

// export function that adds email and name to the holographic collection in the database
export function addEmail(email) {
  const holographicRef = collection(db, "holographic");
  return addDoc(holographicRef, {
    email,
    createdAt: serverTimestamp(),
  });
}

export function addEmailMemo(email) {
  const holographicRef = collection(db, "holographic-memo");
  return addDoc(holographicRef, {
    email,
    createdAt: serverTimestamp(),
  });
}
