import { useState, useEffect } from "react";

import { addEmailMemo } from "./utils/firebase";

// import FadeIn from "react-fade-in";
import FadeInOut from "./FadeInOut";

import logoWhite from "./logo-white.png";
import "./App.css";

function Memo() {
  const [submitted, setSubmitted] = useState(false);
  const [submitting, setSubmitting] = useState(false);
  const [email, setEmail] = useState("");
  const [error, setError] = useState("");
  const [userError, setUserError] = useState(false);

  const isEmail = email => {
    return String(email)
      .toLowerCase()
      .match(
        /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
      );
  };

  const handleChange = e => {
    setEmail(e.target.value);
    setUserError(false);
    setSubmitted(false);
    setSubmitting(false);
    setError(false);
  };

  function handleSubmit(e) {
    e.preventDefault();
    setSubmitting(true);
    setError("");
    setSubmitted(false);
    if (!email) {
      setError("Please enter your email");
      setSubmitting(false);
      return;
    }
    if (!isEmail(email)) {
      setError("Please enter a valid email address");
      setSubmitting(false);
      return;
    }
    addEmailMemo(email).then(() => {
      setSubmitted(true);
      window.location.replace(
        "https://holopin.slab.com/posts/holographic-seed-memo-w9bri2p8"
      );
    });
  }
  return (
    <div className="App">
      <header className="App-header">
        {/* <FadeIn delay={100}> */}
        <FadeInOut show={true} duration={100}>
          <div className="max-w-xl mx-5">
            <img
              src={logoWhite}
              style={{ width: "100%", height: "100%" }}
              alt="logo"
            />
          </div>
        </FadeInOut>
        {/* </FadeIn> */}
        {/* <FadeIn delay={500}> */}
        <FadeInOut show={true} duration={500}>
          <p className="mx-5 font-light text-lg sm:text-xl text-white font-semibold mb-10">
            Please enter your email to view the Memo
          </p>
        </FadeInOut>
        {/* </FadeIn> */}
        {/* <FadeIn delay={1000}> */}
        <FadeInOut show={true} duration={1000}>
          <form onSubmit={handleSubmit}>
            <div className="field-wrapper">
              <input
                id="email"
                type="text"
                name="email"
                className="block w-full mt-2 mb-5 text-base text-white bg-black border border-neutral-700 rounded-md focus:ring-neutral-500 focus:border-neutral-500 p-2.5"
                onChange={e => handleChange(e)}
                placeholder="Email"
              ></input>
              <button
                className={
                  submitting
                    ? `bg-green-400 text-neutral-800 font-semibold text-base py-2 px-4 rounded-md border border-solid border-transparent transition-colors duration-200 ease-in-out`
                    : `bg-green-300 font-semibold text-base py-2 px-4 rounded-md border border-solid border-transparent transition-colors duration-200 ease-in-out`
                }
                type="submit"
                disabled={submitting}
              >
                {submitting ? `Entering...` : `Enter`}
              </button>
            </div>
          </form>
          {/* </FadeIn> */}
        </FadeInOut>
        {userError && (
          <div className="text-red-300 text-base mt-5">{userError}</div>
        )}
        {error && <div className="text-red-300 text-base mt-5">{error}</div>}
        <div className="bg-black text-white pt-40 text-sm">
          <a
            href="mailto:hello@holographic.so"
            className="text-white hover:text-green-300 hover:font-semibold"
          >
            hello@holographic.so
          </a>
        </div>
      </header>
    </div>
  );
}

export default Memo;
